<template>
    <div>
        <div class="d-flex flex-column">
            <div
                id="title-general"
                class="d-flex flex-column flex-md-row justify-content-between align-items-center"
            >
                <div class="d-flex justify-content-between align-items-center">
                    <router-link
                        to="/app/supply-lists"
                        class="rounded-circle bg-info d-flex justify-content-center align-items-center p-2"
                    >
                        <span>🏠</span>
                    </router-link>

                    <div v-if="id" class="d-flex">
                        <h1 class="h3 text-center">
                            &nbsp; Detalle de lista de compras #{{ id }} para
                            {{ merchant.type | merchantInfo("type") }}
                            <span class="text-primary">
                                {{ merchant.name }}
                            </span>
                        </h1>
                    </div>
                    <h1 v-else class="h3">
                        &nbsp; Registrar lista de compras pendientes para
                        {{
                            selectedMerchant.merchantType | merchantInfo("type")
                        }}
                        <span class="text-primary">
                            {{ selectedMerchant.name }}
                        </span>
                    </h1>
                </div>

                <vs-switch
                    v-if="$ability.hasScope('supplyList:Update') && id"
                    v-model="enabledUpdate"
                    :class="enabledUpdate ? 'bg-primary' : 'bg-secondary'"
                >
                    🖊
                </vs-switch>
            </div>
        </div>

        <div v-if="appliedAt" class="d-flex flex-wrap mt-3">
            <div class="list-group-item-warning px-3 py-2 rounded">
                <span class="">
                    Ten presente que ya has efectuado esta lista de compras el
                    día
                    {{ appliedAt }}. Puedes efectuar la lista de compras la
                    cantidad de veces que lo requieras, sin embargo, te
                    recomendamos clonar la lista de compras y aplicar la nueva
                    lista.
                </span>
            </div>
        </div>

        <div class="d-flex flex-wrap mt-2">
            <div
                v-if="id"
                class="col-12 my-3 p-0 d-flex justify-content-around"
            >
                <small class="text-left"> 📆💾 {{ createdAt | date }} </small>
                <small class="text-left"> 📆✏ {{ updatedAt | date }} </small>
                <small v-if="appliedAt" class="text-left">
                    Fecha de último paso a inventario {{ appliedAt | date }}
                </small>
            </div>
            <div class="col-12">
                <span>
                    para llevar un control más eficiente de los productos que
                    necesitas abastecer en tu inventario, es recomendable crear
                    listas de compras. Además, una vez creadas estas listas de
                    compras, podrás utilizarlas varias veces para registrar los
                    mismos productos en tu inventario. Esto te permitirá ahorrar
                    tiempo y asegurarte de no olvidar ningún producto importante
                    en tus compras.
                </span>
            </div>
        </div>

        <div class="d-flex flex-wrap">
            <div class="col-md-6 mt-5 d-flex flex-column">
                <div class="d-flex align-items-center justify-content-between">
                    <strong>Concepto</strong>
                    <vs-tooltip border>
                        <vs-button icon circle border> ℹ </vs-button>
                        <template #tooltip>
                            El concepto es un recordatorio para ti. Puedes
                            registrar una descripción de los productos que vas a
                            comprar o cualquier otro concepto que te ayude a
                            identificar la lista de compras.
                        </template>
                    </vs-tooltip>
                </div>
                <vs-input
                    v-model="concept"
                    border
                    type="text"
                    placeholder="Cuadernos y blogs"
                    class="w-100 opacity-false"
                    :disabled="!enabledUpdate"
                    @focus="onFocusSomeInput"
                    @blur="onBlurConcept"
                >
                    <template
                        v-if="!isValidConcept & introValidators.concept"
                        #message-danger
                    >
                        El concepto debe tener mínimo un caracter
                    </template>
                </vs-input>
            </div>
            <div class="col-md-6 mt-5 d-flex flex-column">
                <div class="d-flex align-items-center justify-content-between">
                    <strong>Proveedor</strong>
                    <vs-tooltip border>
                        <vs-button icon circle border> ℹ </vs-button>
                        <template #tooltip> Nombre de tu proveedor </template>
                    </vs-tooltip>
                </div>
                <vs-input
                    v-model="provider"
                    border
                    type="text"
                    placeholder="Distri Innovalombia"
                    class="w-100 opacity-false"
                    :disabled="!enabledUpdate"
                    @focus="onFocusSomeInput"
                    @blur="onBlurProvider"
                >
                    <template
                        v-if="!isValidProvider & introValidators.provider"
                        #message-danger
                    >
                        El provedor debe tener mínimo un caracter
                    </template>
                </vs-input>
            </div>
            <!-- <div
                class="col-12 col-md-6 mt-5 d-flex flex-column justify-content-between"
            >
                <div class="d-flex align-items-center justify-content-between">
                    <strong>Fecha de recordatorio (Opcional) </strong>
                    <vs-tooltip border>
                        <vs-button icon circle border> ℹ </vs-button>
                        <template #tooltip>
                            Estamos trabajando para que te recibas un mensaje al
                            canal de Telegram del comercio el día del
                            recordatorio.
                        </template>
                    </vs-tooltip>
                </div>
                <b-form-datepicker
                    v-model="notifyAt"
                    locale="es"
                    size="md"
                    :min="minDate"
                    :disabled="!enabledUpdate"
                ></b-form-datepicker>
            </div> -->
        </div>

        <div
            v-show="enabledUpdate"
            class="d-flex flex-wrap mt-5 d-flex flex-column justify-content-center align-items-center"
        >
            <h3>Selecciona las referencias que deseas comprar</h3>
            <inventories-search-scanner
                v-if="enabledUpdate"
                :enabled-scanner="enabledScanner"
                @input="validatePushReferences"
            >
                <template #complement>
                    <product-anonymous-button
                        introduction="Puedes guardar productos aún no registrados en tu lista de compras para usarlos como recordatorio. Una vez los hayas comprado debes crear el producto para posteriormente hacer el ingreso del producto al inventario del producto."
                        label-value="Costo Estimado"
                        label-concept="Nombre del producto"
                        label-quantity="Stock Requeridas"
                        @input="selectProductAnonymous"
                        @open="onFocusSomeInput"
                        @close="enabledScanner = true"
                        @focus="onFocusSomeInput"
                    />
                </template>
            </inventories-search-scanner>
        </div>

        <div class="row z-0 mt-3 mb-4 h-min-72">
            <div class="col-12">
                <h4 v-show="references.length" class="mt-3">Referencias</h4>
                <reference-inventory-items
                    v-for="(reference, index) in references"
                    :key="'A' + index"
                    :enabled="enabledUpdate"
                    class="col-md-11 col-12 mx-auto"
                    :reference="reference"
                    @update="changeReference"
                    @remove="removeReference"
                    @focus="onFocusSomeInput"
                    @blur="onBlurSomeInput"
                />
            </div>
            <div class="col-12">
                <h4 v-show="anonymousReferences.length" class="mt-4 mb-2">
                    Referencias anónimas
                </h4>
                <reference-table-item
                    v-for="(reference, index) in anonymousReferences"
                    :key="'B' + index"
                    :reference="reference"
                    :enabled="enabledUpdate"
                    class="col-md-11 col-12 mx-auto"
                    @update="changeQuantityAnonymousReference"
                    @remove="removeAnonymousReference"
                    @focus="onFocusSomeInput"
                    @blur="onBlurSomeInput"
                />
            </div>
        </div>

        <div class="row z-0 mb-4">
            <div class="col-12 d-flex justify-content-center">
                <div
                    v-if="id"
                    class="d-flex justify-content-between align-items-center"
                >
                    <vs-button
                        v-if="$ability.hasScope('supplyList:Apply')"
                        color="#f07325"
                        size="large"
                        @click="openModalApply"
                    >
                        Efecturar lista de compras
                    </vs-button>
                    <vs-button
                        v-if="enabledUpdate"
                        size="large"
                        @click="updateItemComponent"
                    >
                        Actualizar lista de compras
                    </vs-button>
                </div>
                <vs-button v-else size="large" @click="createItemComponent">
                    Crear lista de compras
                </vs-button>
            </div>
        </div>

        <vs-dialog v-model="isOpenModalApply" scroll overflow-hidden auto-width>
            <template #header>
                <div class="d-flex flex-column">
                    <div class="mx-4 mt-2 d-flex flex-column flex-lg-row">
                        <h3 class="text-center">
                            ¿ Quieres Aplicar La lista de compras ?
                        </h3>
                    </div>
                </div>
            </template>
            <div class="con-content">
                <p>
                    Cuando efectuas la lista de compras pasas las referencias de
                    tu lista de compras al inventario.{{
                        $enabledEcommerceOptions
                            ? "Opcionalmente puedes adjuntar la factura para posteriormente hacer uso de ella en tu reporte fiscal."
                            : ""
                    }}
                </p>
                <ul class="list-group">
                    <li
                        v-if="anonymousReferences.length"
                        class="list-group-item list-group-item-warning px-3 rounded"
                    >
                        <span>
                            No se registrarán los productos anónimos al
                            inventario.
                        </span>
                    </li>
                    <li
                        v-if="appliedAt"
                        class="list-group-item list-group-item-warning px-3 rounded"
                    >
                        <span>
                            Ya se ha efectuado la lista de compras el día
                            {{ appliedAt }}. Si así lo desea, puede volver a
                            realizarla.
                        </span>
                    </li>
                </ul>
                <div class="d-flex flex-column">
                    <vs-checkbox
                        v-if="$enabledEcommerceOptions"
                        v-model="needBill"
                        warn
                    >
                        <span class="text-center">
                            Adjuntar factura de tu compra (Opcional)
                        </span>
                    </vs-checkbox>
                    <div
                        v-if="needBill"
                        class="w-100 mt-3 d-flex justify-content-center"
                    >
                        <private-file-uploader
                            v-model="receiptFile"
                            auto-approve
                            auto-loader
                        />
                    </div>
                    <div class="d-flex justify-content-center">
                        <vs-button color="#f07325" @click="applyItemComponent">
                            Efecturar lista de compras
                        </vs-button>
                    </div>
                </div>
            </div>
        </vs-dialog>
    </div>
</template>

<script>
import { DateTime } from "luxon";
import { mapActions, mapGetters, mapState } from "vuex";

import { titleRegex } from "@/common/lib/regex";

import InventoriesSearchScanner from "@/components/inventories/InventoriesSearchScanner.vue";
import ProductAnonymousButton from "@/components/products/ProductAnonymousButton.vue";
import ReferenceInventoryItems from "@/components/references/ReferenceInventoryItems.vue";
import ReferenceTableItem from "@/components/references/ReferenceTableItem.vue";
import PrivateFileUploader from "@/components/utils/PrivateFileUploader.vue";

export default {
    name: "SupplyListDetails",
    components: {
        PrivateFileUploader,
        ReferenceTableItem,
        ProductAnonymousButton,
        ReferenceInventoryItems,
        InventoriesSearchScanner
    },
    data: () => ({
        id: 0,
        total: 0,
        concept: "",
        provider: "",
        isOpenModalApply: false,
        enabledUpdate: false,
        needBill: false,
        notifyAt: "",
        appliedAt: "",
        updatedAt: "",
        createdAt: "",
        loaderInstance: null,
        enabledScanner: false,
        minDate: DateTime.now().toFormat("yyyy-MM-dd"),
        anonymousReferences: [],
        references: [],
        receiptFile: { url: "", name: "" },
        merchant: { name: "", id: "" },
        introValidators: {
            concept: false,
            provider: false
        }
    }),
    computed: {
        ...mapState("commons", ["selectedMerchant"]),
        ...mapGetters("control", ["backgroundColor", "stateInputDark"]),
        isValidProvider() {
            return titleRegex.test(this.provider);
        },
        isValidConcept() {
            return titleRegex.test(this.concept);
        },
        stateEnabled() {
            return this.enabledUpdate ? this.stateInputDark : "";
        }
    },
    watch: {},
    async mounted() {
        const verifyPageScope = this.$ability.verifyPageScope.bind(this);
        if ("id" in this.$route.params) {
            if (!(await verifyPageScope("supplyList:Get", "/app/supply-lists")))
                return;
            const id = Number(this.$route.params.id);
            this.id = id;

            await this.getBaseItem({
                id,
                merchantId: this.$route.query.merchantId,
                merchantType: this.$route.query.merchantType
            });
        } else if ("idClone" in this.$route.params) {
            if (
                !(await verifyPageScope(
                    "supplyList:Create",
                    "/app/supply-lists",
                    true
                ))
            )
                return;

            this.id = 0;
            this.enabledUpdate = true;

            await this.getBaseItem({
                id: Number(this.$route.params.idClone),
                merchantId: this.$route.query.merchantId,
                merchantType: this.$route.query.merchantType
            });

            this.concept = `${this.concept} CLONE`;
            this.notifyAt = "";
            this.appliedAt = "";
            this.$swal.fire({
                background: this.backgroundColor,
                title: "Estas clonando una lista de compras",
                icon: "info",
                showCloseButton: true
            });
        } else {
            if (
                !(await verifyPageScope(
                    "supplyList:Create",
                    "/app/supply-lists",
                    true
                ))
            )
                return;
            this.enabledUpdate = true;
            this.enabledScanner = true;
        }
    },
    methods: {
        ...mapActions("supplyLists", [
            "getSupplyListById",
            "updateSupplyList",
            "createSupplyList",
            "applySupplyList"
        ]),
        async getBaseItem({ id, merchantId, merchantType }) {
            this.isLoading = true;
            this.loaderInstance = this.$vs.loading({ type: "square-rotate" });
            try {
                const {
                    anonymousReferences,
                    appliedAt,
                    concept,
                    createdAt,
                    merchant,
                    notifyAt,
                    provider,
                    references,
                    updatedAt
                } = await this.getSupplyListById({
                    id,
                    merchantId,
                    merchantType
                });
                this.concept = concept;
                this.provider = provider;
                this.notifyAt = notifyAt;
                this.createdAt = createdAt;
                this.updatedAt = updatedAt;
                this.appliedAt = appliedAt
                    ? DateTime.fromJSDate(new Date(appliedAt))
                          .setZone(this.timezone)
                          .setLocale("es-CO")
                          .toFormat("cccc, LLLL dd / HH:mm")
                    : "";
                this.merchant = { ...merchant };
                this.references = [...references];
                this.anonymousReferences = [...anonymousReferences].map(
                    (item) => ({ ...item, priceOffer: item.cost })
                );
            } catch (error) {
                this.loaderInstance.close();
                await this.$swal.fire({
                    background: this.backgroundColor,
                    title: error.title,
                    text: error.message,
                    icon: error.icon
                });
                if (error.statusCode === 404) {
                    this.$router.push("/app/supply-lists");
                }
            } finally {
                this.isLoading = false;
                this.loaderInstance.close();
            }
        },
        async updateItemComponent() {
            if (!this.validateData()) {
                return;
            }

            this.isLoading = true;
            this.loaderInstance = this.$vs.loading({ type: "square-rotate" });
            try {
                await this.updateSupplyList({
                    ...this.getDataSend(),
                    merchantId: this.merchant.id,
                    merchantType: this.merchant.type
                });
                const sound = this.$sounds.get("success");
                sound.volume(0.9);
                sound.play();
                this.$swal.fire({
                    background: this.backgroundColor,
                    title: "Actualización exitosa",
                    icon: "success",
                    showCloseButton: true
                });
            } catch (error) {
                this.loaderInstance.close();
                const sound = this.$sounds.get("error");
                sound.volume(0.9);
                sound.play();
                this.$swal.fire({
                    background: this.backgroundColor,
                    title: error.title,
                    text: error.message,
                    icon: error.icon
                });
            } finally {
                this.loaderInstance.close();
                this.isLoading = false;
            }
        },
        async createItemComponent() {
            if (!this.validateData()) {
                return;
            }
            this.isLoading = true;
            this.loaderInstance = this.$vs.loading({ type: "square-rotate" });
            try {
                const data = await this.createSupplyList({
                    ...this.getDataSend(),
                    merchantId: this.selectedMerchant.merchantId,
                    merchantType: this.selectedMerchant.merchantType
                });
                this.loaderInstance.close();
                const sound = this.$sounds.get("success");
                sound.volume(0.9);
                sound.play();
                const dialogResult = await this.$swal.fire({
                    background: this.backgroundColor,
                    title: "Creación exitosa",
                    text: "Puedes continuar editando los productos o el concepto de la lista de compras",
                    icon: "success",
                    showDenyButton: true,
                    showCloseButton: true,
                    showCancelButton: true,
                    confirmButtonColor: "#02672D",
                    cancelButtonColor: "#253C99",
                    denyButtonColor: "#f07325",
                    confirmButtonText: "Ver detalle",
                    denyButtonText: "Ir a lista de Lista de comprass",
                    cancelButtonText: "Nueva lista de compras"
                });
                this.id = data.id;
                if (dialogResult.isConfirmed) {
                    this.id = data.id;
                    this.$router.push(`/app/supply-lists/detail/${this.id}`);
                }
                if (dialogResult.isDenied) {
                    this.$router.push("/app/supply-lists/");
                }
                if (dialogResult.isDismissed) {
                    this.introValidators = {
                        concept: false
                    };
                    this.concept = "";
                    this.references = [];
                }
            } catch (error) {
                this.loaderInstance.close();
                const sound = this.$sounds.get("error");
                sound.volume(0.9);
                sound.play();
                this.$swal.fire({
                    background: this.backgroundColor,
                    title: error.title,
                    text: error.message,
                    icon: error.icon
                });
            } finally {
                this.isLoading = false;
            }
        },
        async applyItemComponent() {
            this.isLoading = true;
            this.isOpenModalApply = false;
            this.loaderInstance = this.$vs.loading({ type: "square-rotate" });
            try {
                const { appliedAt } = await this.applySupplyList({
                    ...this.getDataSend(),
                    merchantId: this.merchant.id,
                    merchantType: this.merchant.type
                });
                this.appliedAt = appliedAt;
                const sound = this.$sounds.get("success");
                sound.volume(0.9);
                sound.play();
                this.loaderInstance.close();
                await this.$swal.fire({
                    background: this.backgroundColor,
                    title: "Registro de inventario exitoso",
                    text: "Redirigiendo a home ...",
                    icon: "success",
                    showCloseButton: true,
                    timer: 10000,
                    confirmButtonColor: "#02672D"
                });
                this.$router.push("/app/inventories/history");
            } catch (error) {
                this.loaderInstance.close();
                const sound = this.$sounds.get("error");
                this.isOpenModalApply = true;
                sound.volume(0.9);
                sound.play();
                await this.$swal.fire({
                    background: this.backgroundColor,
                    title: error.title,
                    text: error.message,
                    icon: error.icon
                });
            } finally {
                this.loaderInstance.close();
                this.isOpenModalApply = false;
                this.isLoading = false;
            }
        },
        openModalApply() {
            if (!this.validateData()) {
                return;
            }
            this.isOpenModalApply = true;
        },
        validateData() {
            this.introValidators = {
                concept: true
            };

            const toComplete = [];
            if (!this.isValidConcept) {
                toComplete.push("Concepto");
            }

            if (!this.references.length && !this.anonymousReferences.length) {
                toComplete.push("Referencias");
            }

            if (toComplete.length) {
                this.$swal.fire({
                    background: this.backgroundColor,
                    title: "Referencia invalida",
                    text: `Revisa por favor que todo este completo. Valida los siguientes campos: ${toComplete.join(
                        ", "
                    )}`,
                    icon: "info"
                });
                return false;
            }
            return true;
        },
        getDataSend() {
            return {
                id: this.id,
                concept: this.concept,
                receiptFile: this.receiptFile,
                provider: this.provider,
                ...{
                    ...(this.notifyAt
                        ? {
                              notifyAt: this.notifyAt
                          }
                        : null)
                },
                references: this.references.map((item) => ({
                    id: item.id,
                    quantity: item.quantity,
                    costNew: item.costNew
                })),
                anonymousReferences: [...this.anonymousReferences]
            };
        },
        validatePushReferences(reference) {
            const index = this.references.findIndex(
                (item) => item.id === reference.id
            );

            if (index === -1) {
                this.references.unshift({ ...reference, quantity: 1 });
            } else {
                let references = [...this.references];
                references[index] = {
                    ...references[index],
                    quantity: references[index].quantity + 1
                };
                this.references = [...references];
            }
        },
        changeReference({ costNew, id, priceNew, provider, quantity }) {
            const index = this.references.findIndex((item) => item.id == id);
            let references = [...this.references];
            references[index] = {
                ...references[index],
                quantity,
                priceNew,
                costNew,
                provider
            };
            this.references = [...references];
        },
        removeReference({ id }) {
            const index = this.references.findIndex((item) => item.id == id);
            let references = [...this.references];
            references.splice(index, 1);
            this.references = [...references];
        },
        selectProductAnonymous({ priceOffer, product, quantity }) {
            this.anonymousReferences.push({
                cost: priceOffer,
                priceOffer,
                quantity,
                product
            });
        },
        changeQuantityAnonymousReference({ id, quantity }) {
            const index = this.anonymousReferences.findIndex(
                (item) => item.id == id
            );
            let anonymousReferences = [...this.anonymousReferences];
            anonymousReferences[index] = {
                ...anonymousReferences[index],
                quantity
            };
            this.anonymousReferences = [...anonymousReferences];
        },
        removeAnonymousReference({ id }) {
            const index = this.anonymousReferences.findIndex(
                (item) => item.id === id
            );
            let anonymousReferences = [...this.anonymousReferences];
            anonymousReferences.splice(index, 1);
            this.anonymousReferences = [...anonymousReferences];
        },
        onBlurConcept() {
            this.introValidators.concept = true;
            this.onBlurSomeInput();
        },
        onBlurProvider() {
            this.introValidators.provider = true;
            this.onBlurSomeInput();
        },
        onBlurSomeInput() {
            this.enabledScanner = true;
        },
        onFocusSomeInput() {
            this.enabledScanner = false;
        }
    }
};
</script>
